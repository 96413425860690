import type * as Fabric from '@postermywall/fabricjs-2';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {AddOnData, VariationData, Cell} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getIconSvg} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {rgbToHexString} from '@Utils/color.util';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {BOLD_STROKE_WIDTH_FACTOR, DEFAULT_SPACING_BETWEEN_ITEMS, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText, Textbox} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

export class MenuLayout2 extends Layout {
  public layoutType: LayoutTypes.MENU_LAYOUT_2 = LayoutTypes.MENU_LAYOUT_2;
  declare item: MenuItem;

  public setStylesForHighlightedItems(): void {
    throw new Error('Method not implemented.');
  }

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.setExtraStyles();
    this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
  }

  /**
   * This function inserts the dots between the description and price then re-layout the items
   */
  setExtraStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Array<Fabric.Group>;
    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects();
      const nameAndDescriptionGroup = menuItems[0] as Fabric.Group;
      const nameAndDescriptionItems = nameAndDescriptionGroup.getObjects();
      const descriptionAndPrice = nameAndDescriptionItems[1] as Fabric.Group;
      const sizesGroup = menuItems[1] as Fabric.Group | undefined;
      const priceItemWidth = sizesGroup?.getObjects().length ? sizesGroup.width + this.item.fabricObject.padding : descriptionAndPrice.getObjects()[1].width;
      const descriptionItem = descriptionAndPrice.getObjects()[0] as Fabric.IText;
      const hasPrice = sizesGroup?.getObjects().length || (descriptionAndPrice.getObjects()[1] as Fabric.IText).text !== '';

      let descWidth;
      if (this.item.page.poster.isHighRes) {
        // At hires, xSpacing is already included in the view's width, so no need to add that
        descWidth = this.item.fabricObject.width - priceItemWidth + (!hasPrice ? this.item.xSpacing : -this.item.fabricObject.padding);
      } else {
        descWidth = this.item.fabricObject.width + this.item.xSpacing - priceItemWidth;
      }
      descriptionItem.set({
        width: descWidth,
      });

      // only add dots if the menu item has a price value.
      if (hasPrice) {
        const {textLines} = descriptionItem;
        const dottedText = new IText('.', {
          editable: false,
          fontSize: descriptionItem.fontSize,
          fontFamily: descriptionItem.fontFamily,
          charSpacing: this.item.textStyles.letterSpacing,
          objectCaching: !this.item.page.poster.isHighRes,
        });
        const descriptionTextOnly = new IText(textLines[textLines.length - 1], {
          editable: false,
          fontSize: descriptionItem.fontSize,
          fontFamily: descriptionItem.fontFamily,
          charSpacing: this.item.textStyles.letterSpacing,
          objectCaching: !this.item.page.poster.isHighRes,
        });

        const numberOfDots = Math.max(0, 1 + (descriptionItem.width - this.edgePadding - descriptionTextOnly.width) / dottedText.width);
        const textLength = descriptionItem.text.length;

        descriptionItem.set({
          text: descriptionItem.text + '.'.repeat(numberOfDots),
        });
        descriptionItem.setSelectionStyles({underline: this.item.underLine2}, 0, textLength);
        descriptionItem.setSelectionStyles({linethrough: this.item.lineThrough2}, 0, textLength);
      }

      this.verticallyStackItems(descriptionAndPrice, 0, 0);
      this.doBottomAlign(descriptionAndPrice);
      this.horizontallyStackItems(nameAndDescriptionGroup, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
    }
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in view, specific to this layout.
   * @override
   */
  async setItems(): Promise<void> {
    let i;

    const textObjectCaching = !this.item.page.poster.isHighRes;
    const columnMap = this.item.getColumnMap();
    const horizontalGroups: Array<Fabric.Group> = [];
    const rows = this.item.getNoOfRows();

    for (let a = 0; a < rows; a++) {
      const nameAndIcons = new Group([], {objectCaching: false});
      const descriptionAndPrice = new Group([], {objectCaching: false});
      const addOnsGroup = new Group([], {objectCaching: false});
      const sizesGroup = new Group([], {objectCaching: false});

      for (const [cellType, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        switch (cellType) {
          case CellType.NAME: {
            const t = cells[a].getValue() as string;
            nameAndIcons.insertAt(
              nameAndIcons.getObjects().length,
              new IText(t.toUpperCase(), {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;
          }

          case CellType.DESCRIPTION: {
            /*
             * At highres, we don't let the Textbox determine text wrapping. Instead, we specify wrapping ourselves that matches
             * what the user saw in their browser when the poster was saved
             */
            let t = cells[a].getValue() as string;
            let tb;
            if (this.item.page.poster.isHighRes) {
              t = this.getWrapping(this.item, a, t);
              if (
                (columnMap[CellType.PRICE] && columnMap[CellType.PRICE][a].getValue()) ||
                (columnMap[CellType.VARIATION] && (columnMap[CellType.VARIATION][a].getValue() as VariationData[]).length)
              ) {
                tb = new IText(t, {editable: false, objectCaching: textObjectCaching});
              } else {
                tb = new Textbox(t, {editable: false, objectCaching: textObjectCaching});
              }
            } else {
              tb = new Textbox(t, {
                editable: false,
                padding: 0,
                objectCaching: textObjectCaching,
              });
            }
            descriptionAndPrice.insertAt(descriptionAndPrice.getObjects().length, tb);
            break;
          }

          case CellType.PRICE: {
            const t = cells[a].getValue() as string;
            descriptionAndPrice.insertAt(
              descriptionAndPrice.getObjects().length,
              new IText(t.toUpperCase(), {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;
          }

          case CellType.ICONS: {
            const t = cells[a].getValue() as Array<string>;
            for (let z = 0; z < t.length; z++) {
              // eslint-disable-next-line no-await-in-loop
              const icon = await getIconSvg(t[z]);

              if (icon) {
                const scale = this.getScaleForMenuIcon(icon, this.item.iconsSize);
                icon.set({
                  scaleX: scale,
                  scaleY: scale,
                });
                nameAndIcons.insertAt(nameAndIcons.getObjects().length, icon);
              }
            }
            break;
          }

          case CellType.VARIATION: {
            const t = cells[a].getValue() as Array<VariationData>;
            if (t.length > 0) {
              descriptionAndPrice.remove(descriptionAndPrice.getObjects()[descriptionAndPrice.getObjects().length - 1]);
              for (i = 0; i < t.length; i++) {
                const g: Array<Fabric.IText> = [];
                g.push(
                  new IText(t[i].name, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                g.push(
                  new IText(t[i].price, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                sizesGroup.insertAt(sizesGroup.getObjects().length, new Group(g, {objectCaching: false}));
              }
            }
            break;
          }
          case CellType.ADDON: {
            const t = cells[a].getValue() as Array<AddOnData>;
            if (t.length > 0) {
              for (i = 0; i < t.length; i++) {
                addOnsGroup.insertAt(
                  addOnsGroup.getObjects().length,
                  new IText(`${t[i].name} ${t[i].price}`, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
              }
            }
            break;
          }
          default:
            break;
        }
      }

      const verticalGroup = new Group([nameAndIcons, descriptionAndPrice], {objectCaching: false});
      const horizontalGroup = [verticalGroup];

      if (sizesGroup.getObjects().length > 0) {
        horizontalGroup.push(sizesGroup);
      }
      if (addOnsGroup.getObjects().length > 0) {
        verticalGroup.add(addOnsGroup);
      }

      horizontalGroups.push(new Group(horizontalGroup, {objectCaching: false}));
    }
    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(horizontalGroups);
  }

  /**
   * Sets the view styles specific to this layout
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Array<Fabric.Group>;
    let strokeWidthForFF1 = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForFF2 = 0;
    let strokeColor = rgbToHexString(this.item.textStyles.fill.fillColor[0], 1);
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 += !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth * 1.3;
      strokeColor = rgbToHexString(this.item.textStyles.strokeColor, 1);
    }

    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects();
      const nameAndDescriptionGroup = menuItems[0] as Fabric.Group;
      const nameAndDescriptionItems = nameAndDescriptionGroup.getObjects();
      const nameAndIcon = nameAndDescriptionItems[0] as Fabric.Group;
      const descriptionAndPrice = nameAndDescriptionItems[1] as Fabric.Group;
      const sizesGroup = menuItems[1] !== undefined ? (menuItems[1] as Fabric.Group) : null;
      const addOnGroup = nameAndDescriptionItems[2] as Fabric.Group;

      nameAndIcon.getObjects()[0].set({
        fontSize: this.item.textStyles.fontSize * 1.3,
        fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
        strokeWidth: strokeWidthForFF1,
        strokeLineJoin: 'round',
        paintFirst,
        stroke: strokeColor,
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      });

      const icons = nameAndIcon.getObjects();
      for (let j = 1; j < icons.length; j++) {
        icons[j].set({
          fill: rgbToHexString(this.item.iconsColor, this.item.alpha),
        });
      }
      descriptionAndPrice.getObjects()[0].set({
        fontFamily: `'${fontFamily2WithVariation}'`,
        fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
      });
      if (strokeWidthForFF2) {
        descriptionAndPrice.getObjects()[0].set({
          strokeWidth: strokeWidthForFF2,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
        });
      }

      if (sizesGroup && sizesGroup.getObjects().length > 0) {
        const sizeGroupStyle = {
          fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
          strokeWidth: strokeWidthForFF1,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
          underline: this.item.textStyles.underLine,
          linethrough: this.item.textStyles.lineThrough,
        };
        const szs = sizesGroup.getObjects() as Array<Fabric.Group>;
        for (let t = 0; t < szs.length; t++) {
          szs[t].getObjects()[0].set(sizeGroupStyle);
          szs[t].getObjects()[1].set(sizeGroupStyle);
        }
      } else {
        descriptionAndPrice.getObjects()[descriptionAndPrice.getObjects().length - 1].set({
          strokeWidth: strokeWidthForFF1,
          strokeLineJoin: 'round',
          paintFirst,
          fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
          stroke: strokeColor,
          underline: this.item.textStyles.underLine,
          linethrough: this.item.textStyles.lineThrough,
        });
      }

      this.verticallyStackItems(nameAndIcon, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      this.verticallyCenterItems(nameAndIcon);
      this.verticallyStackItems(descriptionAndPrice, 0, 0);
      this.horizontallyStackItems(nameAndDescriptionGroup, DEFAULT_SPACING_BETWEEN_ITEMS, 0);

      if (sizesGroup !== null) {
        const sizes = sizesGroup.getObjects() as Array<Fabric.Group>;
        for (let a = 0; a < sizes.length; a++) {
          this.horizontallyStackItems(sizes[a], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
          this.horizontallyCenterItems(sizes[a]);
        }
        this.verticallyStackItems(sizesGroup, 10, 0);
      }
      if (addOnGroup) {
        addOnGroup.set({
          fontFamily: `'${fontFamily2WithVariation}'`,
        });
        addOnGroup.getObjects().forEach((obj) => {
          obj.set({
            fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
            underline: this.item.underLine2,
            linethrough: this.item.lineThrough2,
          });
          if (strokeWidthForFF2) {
            obj.set({
              strokeWidth: strokeWidthForFF2,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: rgbToHexString(this.item.textStyles.fill.fillColor[0], 1),
            });
          }
        });
        this.verticallyStackItems(addOnGroup, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
        this.verticallyCenterItems(addOnGroup);
      }
      this.horizontallyStackItems(nameAndDescriptionGroup, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      this.doBottomAlign(descriptionAndPrice);
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   */
  layoutItemsInsideGroups(): void {
    const groups = this.item.fabricObject.getObjects() as Array<Fabric.Group>;

    for (let i = 0; i < groups.length; i++) {
      const newDim = this.getNewViewDimensions(groups[i], 'vertical');
      groups[i].set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
        padding: 0,
      });
      this.verticallyStackItems(groups[i], this.edgePadding, 0);
      this.doBottomAlign(groups[i]);
    }
  }

  getWrappingInfo(): Array<Array<string>> {
    const groups = this.item.fabricObject.getObjects() as Array<Fabric.Group>;
    const wrappingData = [];

    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects();
      const description = ((menuItems[0] as Fabric.Group).getObjects()[1] as Fabric.Group).getObjects()[0];

      if (description instanceof Textbox) {
        wrappingData.push(description.textLines);
      } else {
        wrappingData.push('');
      }
    }
    return wrappingData as Array<Array<string>>;
  }
}
