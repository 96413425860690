export const USER_IMAGE_UPLOADS_GRID_ID = 'USER_IMAGE_UPLOADS_GRID';
export const USER_AI_GENERATED_IMAGE_GRID_ID = 'USER_AI_GENERATED_IMAGE_GRID';
export const USER_BG_IMAGE_UPLOADS_GRID_ID = 'USER_BG_IMAGE_UPLOADS_GRID';
export const USER_VIDEO_UPLOADS_GRID_ID = 'USER_VIDEO_UPLOADS_GRID';
export const USER_AUDIO_UPLOADS_GRID_ID = 'USER_AUDIO_UPLOADS_GRID';
export const USER_LOGOS_GRID_ID = 'USER_LOGOS_GRID';
export const USER_UPLOADS_SELECTION_GRID_ID = 'USER_UPLOADS_SELECTION_GRID';
export const COLLECTION_GRID_ID = 'UPLOADS_COLLECTION_GRID';
export const COLLECTION_MEDIA_GRID_ID = 'MEDIA_UPLOADS_COLLECTION_GRID';
export const FACEBOOK_PHOTOS_GRID = 'FACEBOOK_PHOTOS_GRID';

export const FB_PERMISSIONS = 'email,user_photos';

export const USER_UPLOADS_MEDIA_TABS = 'USER_UPLOADS_MEDIA_TABS';
export const USER_UPLOADS_MEDIA_SUB_TABS = 'USER_UPLOADS_MEDIA_SUB_TABS';

export enum UploadsMediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE_BACKGROUND = 'IMAGE_BACKGROUND',
}

export enum UploadsMediaSubTab {
  UPLOADS = 'UPLOADS',
  LOGO = 'LOGO',
  COLLECTION = 'COLLECTION',
  AI_IMAGE = 'AI_IMAGE',
}

export interface AlbumData {
  aid: string;
  cover: string;
  name: string;
  size: string;
}

export interface FacebookResponse {
  albums: Array<AlbumData>;
}

export interface FacebookPhotosResponse {
  id: string;
  s: string;
  b: string;
  width: number;
  height: number;
}

export enum UserMediaPanelType {
  DEFAULT = 'DEFAULT',
  RECORD_VIDEO = 'RECORD_VIDEO',
  RECORD_AUDIO = 'RECORD_AUDIO',
  RECORD_PHOTO = 'RECORD_PHOTO',
  AI_IMAGE = 'AI_IMAGE',
}
