import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {BackgroundObject} from '@PosterWhiteboard/page/page-background.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {ImageBackground} from '@PosterWhiteboard/page/background/image-background.class';
import type {ColorBackground} from '@PosterWhiteboard/page/background/color-background.class';
import type {DeepPartial} from '@/global';

export enum BackgroundTypeName {
  COLOR = 'color',
  IMAGE = 'image',
}

export interface BaseBackgroundObject {
  type: BackgroundTypeName;
}

export abstract class Background extends CommonMethods {
  public abstract type: BackgroundTypeName;
  public page: Page;

  constructor(page: Page) {
    super();
    this.page = page;
  }

  public async updateFromObject(object: DeepPartial<BackgroundObject>, {updateRedux = true, undoable = true, doInvalidate = true}: UpdateFromObjectOpts = {}): Promise<void> {
    this.copyVals(object);
    if (doInvalidate) {
      await this.invalidate();
    }

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
  }

  public isImage(): this is ImageBackground {
    return this.type === BackgroundTypeName.IMAGE;
  }

  public isColor(): this is ColorBackground {
    return this.type === BackgroundTypeName.COLOR;
  }

  public abstract invalidate(): Promise<void>;

  public onBackgroundCreated(): void {}
}
